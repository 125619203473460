const _ = require("lodash")

const allProjects = (
  allProjectsYaml,
  repositories,
  repositoriesContributedTo
) => {
  const repos = repositories.edges
    .map(repo => repo.node)
    .concat(repositoriesContributedTo.edges.map(repo => repo.node))

  const allProjects = allProjectsYaml.edges.map(
    ({ node: { category, projects } }) => ({
      category,
      projects: projects.map(project => {
        const githubProject = repos.find(
          repo =>
            (project.name.indexOf("/") > -1
              ? repo.nameWithOwner
              : repo.name) === project.name
        )
        const mergedProject = _.mergeWith({}, githubProject, project, (o, s) =>
          _.isNull(s) ? o : s
        )
        return mergedProject
      }),
    })
  )

  return allProjects
}

module.exports.allProjects = allProjects;
