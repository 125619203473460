import * as React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Box, Heading, Text, Image } from "grommet"
import { Star as StarIcon, FormView as WatchIcon } from "grommet-icons"

const Card = styled(Box).attrs({ elevation: "small", justify: "between" })`
  background: #f5d5cf;
  border-radius: 20px;
  padding: 30px;
  padding-top: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

const ProjectCard = ({
  name,
  customName,
  description,
  url,
  homepageUrl,
  projectUrl,
  image,
  stargazerCount,
  watchers,
  redirect,
}) => (
  <Card
    onClick={() =>
      redirect || (!homepageUrl && !projectUrl)
        ? window.open(url)
        : navigate((customName || name).replace(/ /g, "-"))
    }
  >
    <Box
      direction="row"
      justify="between"
      align="center"
      margin={{ top: "small", right: "small" }}
      gap="small"
    >
      <Box>
        <Heading level="3" size="medium" margin={{ bottom: "xxsmall" }}>
          {customName || name}
        </Heading>
        <Box direction="row" margin={{ bottom: "medium" }} gap="small">
          <Box direction="row">
            <WatchIcon /> {(watchers && watchers.totalCount) || 0}
          </Box>
          <Box direction="row">
            <StarIcon /> {stargazerCount || 0}
          </Box>
        </Box>
        <Text>{description}</Text>
      </Box>
      {image && (
        <Box
          height="70px"
          width="70px"
          round="small"
          overflow="hidden"
          flex="grow"
          align="end"
        >
          <Image src={image} fit="contain" />
        </Box>
      )}
    </Box>
  </Card>
)

export default ProjectCard
