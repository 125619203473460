import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Heading, Grid } from "grommet"
import ProjectCard from "../components/projectCard"
import { allProjects } from "../utils/projects"
import { graphql } from "gatsby"

const CodePage = ({ data }) => {
  const {
    allProjectsYaml,
    github: {
      viewer: { repositories, repositoriesContributedTo },
    },
  } = data

  const allMergedProjects = allProjects(
    allProjectsYaml,
    repositories,
    repositoriesContributedTo
  )

  return (
    <Layout>
      <Seo title="code" />
      <Heading> my projects</Heading>
      <Box flex="shrink">
        {allMergedProjects.map(({ category, projects }) => (
          <Box key={category}>
            <Heading level="2" style={{ position: "sticky", top: 0 }}>
              {category}
            </Heading>
            <Grid gap="medium" columns="medium" rows="auto">
              {projects.map(project => (
                <ProjectCard key={project.name} {...project} />
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
      <Heading level="4">
        {allMergedProjects.reduce(
          (len, { projects }) => len + projects.length,
          0
        )}{" "}
        projects and counting....
      </Heading>
    </Layout>
  )
}

export const query = graphql`
  fragment Repo on GitHub_Repository {
    name
    nameWithOwner
    description
    stargazerCount
    watchers(first: 1) {
      totalCount
    }
    homepageUrl
    url
  }

  query CodeQuery {
    allProjectsYaml {
      edges {
        node {
          category
          projects {
            name
            customName
            image
            url
            homepageUrl
            projectUrl
            description
          }
        }
      }
    }

    github {
      viewer {
        repositories(first: 100) {
          edges {
            node {
              ...Repo
            }
          }
        }
        repositoriesContributedTo(first: 100, privacy: PUBLIC) {
          edges {
            node {
              ...Repo
            }
          }
        }
      }
    }
  }
`

export default CodePage
